import request from "@/utils/request";
const controller = "/files";
// const controller2 = "/1";

//上传图片
export function uploadFile(data: any) {
  return request({
    url: `${controller}/uploadFile`,
    method: "post",
    data,
  });
}

// 获取列表
export function getPageList(params: any) {
  return request({
    url: `${controller}/getPageList`,
    method: "GET",
    params,
  });
}
// 获取详情
export function getInfo(params: any) {
  return request({
    url: `${controller}/getInfo`,
    method: "post",
    params,
  });
}

// 更新数据
export function Update(data: any) {
  return request({
    url: `${controller}/Update`,
    method: "post",
    data,
  });
}
// 删除数据
export function Delete(data: any) {
  return request({
    url: `${controller}/Delete`,
    method: "post",
    params: data,
  });
}
